import React from "react"
import styled from "styled-components"

import podcastLinksData from "../content/podcastLinks.json"
import googlePodcastIcon from "../images/google-podcasts.svg"

const PodcastLinksContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 48px 48px 48px 48px;

  .fa-stack {
    /* Fix width of stacks to be same as height */
    width: 2em;
    height: 2em;
    line-height: 2em;
  }

  .fa-circle {
    text-shadow: 0 2px 4px rgba(104, 95, 116, 0.3);
  }

  .fa-google-podcast {
    width: calc(100% / 2);
    height: calc(100% / 2);
    top: calc(100% / 4);
    left: calc(100% / 4);
  }

  .fa-rss {
    top: -1px;
    left: 1px;
  }

  .link {
    font-size: 24px;
    color: white;

    &:last-child {
      margin-right: 0;
    }
  }
`

const IconType = styled.i``
const ImageType = styled.img``

const PodcastLinksList = () => {
  return podcastLinksData.map(link => {
    const { url, name, color, icon, iconType } = link
    const iconStyleClass = iconType === "brand" ? "fab" : "fas"
    const IconTag = iconType === "svg" ? ImageType : IconType
    return (
      <a
        key={name}
        alt={name}
        href={url}
        className="link"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="fa-stack fa-md">
          <i className="fas fa-circle fa-stack-2x" />
          <IconTag
            className={`${iconStyleClass} fa-${icon} fa-stack-1x`}
            src={`${googlePodcastIcon}`}
            style={{ color: color }}
            alt={name}
          />
        </span>
      </a>
    )
  })
}

const PodcastLinks = props => {
  const { layout = "center", style: passedStyle } = props
  const style = {
    justifyContent: layout,
    marginLeft: layout === "left" ? "-6px" : "0",
    ...passedStyle,
  }
  return (
    <PodcastLinksContainer style={style}>
      <PodcastLinksList />
    </PodcastLinksContainer>
  )
}

export default PodcastLinks
