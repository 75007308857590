import React from "react"
import styled from "styled-components"
import { FormattedDate } from "./elements"
import Image from "./image"

export const ArticleTitle = styled.h3`
  margin: 0;
`

export const ArticleTitleLarge = styled.h1`
  margin-top: -8px;
`

export const ArticleExcerpt = styled.p`
  margin: 0;
`

const PublishedDateContainer = styled.h4`
  margin: 4px 0 22px;
  color: #95979a;
  font-size: 14px;
  text-transform: capitalize;
`

export const ArticlePublishedDate = props => {
  const { date } = props
  return (
    <PublishedDateContainer {...props}>
      <FormattedDate date={new Date(date)} />
    </PublishedDateContainer>
  )
}

const ImageContainer = styled.div`
  position: relative;
  display: block;
  min-height: 244px;
  max-height: 100%;
`

const ImageBackground = styled.div`
  background-color: #fafafa;
  border: 1px solid #dedede;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`

const ImageOverlay = styled.div`
  background-color: #00000088;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 40px;

  display: flex;
  align-items: center;
  justify-content: left;

  & h1 {
    color: #ffffff;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

export const ArticleHero = props => {
  const { image, title } = props
  if (!image) {
    return null
  }
  const imageStyle = {
    objectPosition: "top center",
  }
  return (
    <ImageContainer {...props}>
      <ImageBackground />
      <ImageWrapper>
        <Image source={image} alt={title} style={imageStyle} />
      </ImageWrapper>
      {title && (
        <ImageOverlay>
          <ArticleTitle>{title}</ArticleTitle>
        </ImageOverlay>
      )}
    </ImageContainer>
  )
}
