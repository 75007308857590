import md5 from "md5"

export function* intersperse(array, separator) {
  let first = true
  for (const x of array) {
    if (!first) yield separator
    first = false
    yield x
  }
}

export function profilePictureURLFromEmail(email) {
  return `https://www.gravatar.com/avatar/${md5(email)}?s=200`
}

export const formatPercentage = input => {
  return formatNumber(input, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatCurrency = (
  input,
  currencyCode = "EUR",
  locale = "it-IT"
) => {
  return formatNumber(input, {
    style: "currency",
    locale: locale,
    currency: currencyCode,
  })
}

export const formatNumber = (number = 0, options = {}) => {
  const locale = options["locale"] || "en-US"
  return new Intl.NumberFormat(locale, options).format(number)
}

export const test = () => {
  console.log("Test")
}
